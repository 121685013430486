import { Component, OnInit } from "@angular/core";
import { HttpClient } from '@angular/common/http';

@Component({
  selector: "app-index",
  templateUrl: "./index.component.html",
})


export class IndexComponent implements OnInit {
  constructor(private http: HttpClient) {
    console.log(this.sendEmail());
    this.sendEmail()
  }

  ngOnInit(): void { }

  sendEmail() {
    return this.http.post('https://mandrillapp.com/api/1.0/messages/send.json',
      {
        key: 'aeec4b0491289d410800f2d802de525f-us5',
        message: {
          html: '<p>Example HTML content</p>',
          subject: 'example subject',
          from_email: 'deylert89@gmail.com',
          from_name: 'Example Name',
          to: [
            {
              email: 'deylert89@gmail.com',
              name: 'Recipient Name',
              type: 'to'
            }
          ],
          headers: {
            'Reply-To': 'deylert89@gmail.com'
          }
        }

      });
  }
}

